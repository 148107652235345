<script>
import Layout from '../../layouts/main.vue'
import PageHeader from '@/components/page-header'


export default {
    components: { Layout, PageHeader },
}
</script>
    
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <h3 class="fw-bold mb-4">Search Group/ Company</h3>
        <form action="">
            <div class="d-flex justify-content-evenly">
                <div class="col-8 d-flex">
                    <div class="col-4">
                        <input type="text" name="search_company" id="" class="form-control" placeholder="Name Company">

                    </div>
                    <div class="col-1">
                        <select name="" id="" class="form-control">
                            <option value="A-Z">A-Z</option>
                            <option value="Z-A">Z-A</option>
                            <option value="ID">ID</option>
                        </select>
                    </div>
                </div>

                <div class="col-2">
                    <router-link class="fw-bold h6 text-primary" to="/new-company">+ New Group</router-link>

                    <!--<a href="" class="m-3 fw-bold">+ New Group</a> -->
                    <!--<a href="" class="fw-bold">import</a> -->
                </div>
            </div>
        </form>
        <table class="table  table-hover mt-4">
            <thead>
                <th></th>
                <th>Group / Company</th>
                <th>ID</th>
            </thead>
            <tbody>
                <tr class="text-primary">
                    <th>
                        <a href="#">View</a>
                    </th>
                    <th>Five Star Management</th>
                    <th>(ID: G104)</th>
                </tr>

                <tr class="text-primary">
                    <th>
                        <a href="#">View</a>
                    </th>
                    <th>Five Star Management</th>
                    <th>(ID: G104)</th>
                </tr>

                <tr class="text-primary">
                    <th>
                        <a href="#">View</a>
                    </th>
                    <th>Five Star Management</th>
                    <th>(ID: G104)</th>
                </tr>
            </tbody>
        </table>
    </Layout>
</template>
